<template>
	<el-card>
		<div class="d-flex flex-row flex-wrap">
			<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false"
				@onload="q.channel=queryForm.channel;getList()" :clearable="false"></form-item-sites>
			<form-item label="BM ID:" style="width:200px;">
				<el-input v-model="queryForm.bmId" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item label="Account ID:" style="width:250px;">
				<el-input v-model="queryForm.accountId" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item label="APP ID:" style="width:250px;">
				<el-input v-model="queryForm.appId" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" @click="visible.add=true">添加配置</el-button>
			</form-item>
		</div>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<google-table class="mt-3 w-100" :tableData="tableData" @changePublish="changeActive" @editRow="editRow" @delRow="delRow">
		</google-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="140px">
				<el-form-item-sites :abbr="false" v-model="form.channel" prop="channel" label-width="140px">
				</el-form-item-sites>
				<el-form-item label="login_customer_id:" prop="login_customer_id">
					<el-input v-model.trim="form.login_customer_id" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="developer_token:" prop="developer_token">
					<el-input v-model.trim="form.developer_token" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="client_id:" prop="client_id">
					<el-input v-model.trim="form.client_id" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="client_secret:" prop="client_secret">
					<el-input v-model.trim="form.client_secret" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="refresh_token:" prop="refresh_token">
					<el-input v-model.trim="form.refresh_token" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="customer_id:" prop="customer_id">
					<el-input v-model.trim="form.customer_id" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import GoogleTable from '@/components/token/google-table.vue'
	import config from './minixs/index.js'
	const InitialForm = {
		channel: '',
		login_customer_id: '',
		developer_token: '',
		client_id: '',
		client_secret: '',
		refresh_token: '',
		customer_id:''
	}
	export default {
		mixins: [config],
		components: {
			GoogleTable
		},
		data() {
			return {
				queryForm: {
					channel: '',
					bmId: '',
					accountId:'',
					appId: ''
				},
				tableData: [],
				q: {
					channel: ''
				},
				visible: {
					add: false
				},
				form: {
					...InitialForm
				},
				rules: {
					channel: [{
						required: true,
						message: '站点缩写不能为空'
					}],
					login_customer_id: [{
						required: true,
						message: 'login_customer_id不能为空'
					}],
					developer_token: [{
						required: true,
						message: 'developer_token不能为空'
					}],
					client_id: [{
						required: true,
						message: 'client_id不能为空'
					}],
					client_secret: [{
						required: true,
						message: 'client_secret不能为空'
					}],
					refresh_token: [{
						required: true,
						message: 'refresh_token不能为空'
					}],
					customer_id: [{
						required: true,
						message: 'customer_id不能为空'
					}],
				},
				namespace: 'google-token'
			}
		},
		methods: {
			editRow(item) {
				this.form = {
					...InitialForm,
					...item
				}
				this.visible.add = true
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {
						...InitialForm
					}
				}
			}
		}
	}
</script>

<style>
</style>
