<template>
	<el-table height="auto" :data="tableData" border>
		<el-table-column label="站点" prop="channelCode" min-width="50">
		</el-table-column>
		<el-table-column label="login_customer_id" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.login_customer_id"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="developer_token" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.developer_token"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="customer_id" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.customer_id"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="Account Name" min-width="80">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.accountName"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="client_id" min-width="75">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.client_id"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="client_secret" min-width="100">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.client_secret"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="refresh_token" min-width="150">
			<template slot-scope="scope">
				<text-truncate :text="scope.row.refresh_token"></text-truncate>
			</template>
		</el-table-column>
		<el-table-column label="权限状态" prop="token" min-width="100">
			<template slot-scope="scope">
			</template>
		</el-table-column>
		<el-table-column label="发布状态" min-width="80">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="150">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
				<el-button class="text-danger" type="text" @click="$emit('delRow', scope.row)">删除</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	export default {
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
